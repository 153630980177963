import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import JobsPage from '../components/JobsPage';

class Jobs extends React.PureComponent {
  constructor(args) {
    super(args);
  }

  render() {
    const { data } = this.props;
    const { seo } = this.props.data.jobsEnJson;
    const { allMarkdownRemark } = this.props.data;

    return (
      <Layout data={this.props.data} location={this.props.location}>
        <SEO {...seo} />

        <JobsPage
          jobs={allMarkdownRemark.edges}
          preHeader={"Werden Sie ein Teil von Impect"}
          header={"Karriere"}
          headerImage={this.props.data.file.childImageSharp.fixed}
        />
      </Layout>
    );
  }
}

export default Jobs;

export const pageQuery = graphql`
    query JobsDeQuery {
        site{
            siteMetadata{
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        file(relativePath: { eq: "header-bg-medis.png" }) {
            childImageSharp {
                fixed(width: 2500 height: 793 quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        jobsEnJson {
            seo {
                title
                description
                lang
                keywords
            }
        }
        allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { seo: {lang: { eq: "de" } }  } }
            limit: 1000
        ) {
            edges {
                node {
                    frontmatter {
                        seo {
                            lang
                        }
                        date
                        jobTitle
                        jobType
                        location
                    }
                }
            }
        }
    }
`;
